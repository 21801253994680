import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'TEST-EUSD LP',
    lpAddresses: {
      97: '',
      31229: '0x474c8a9D565F211d02f644822E7a0401B48e264a',
    },
    tokenSymbol: 'TEST',
    tokenAddresses: {
      97: '',
      31229: '0x3b21662f581361c72d72f46b6982795241F55236',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'TEST-EGC LP',
    lpAddresses: {
      97: '',
      31229: '0xB281d6D62f8e027bDCe0Ec3aD9606EF346E25483',
    },
    tokenSymbol: 'TEST',
    tokenAddresses: {
      97: '',
      31229: '0x3b21662f581361c72d72f46b6982795241F55236',
    },
    quoteTokenSymbol: QuoteToken.EGC,
    quoteTokenAdresses: contracts.wegc,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'EGC-EUSD LP',
    lpAddresses: {
      97: '',
      31229: '0xB64983B0c2460Bc686d217320427a30341AbA951',
    },
    tokenSymbol: 'EGC',
    tokenAddresses: {
      97: '',
      31229: '0x87e95a88EF421dc51b385FAFA87f64F21f5E3041',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'TEST',
    lpAddresses: {
      97: '',
      31229: '0x474c8a9D565F211d02f644822E7a0401B48e264a', // TEST-EUSD LP
    },
    tokenSymbol: 'TEST',
    tokenAddresses: {
      97: '',
      31229: '0x3b21662f581361c72d72f46b6982795241F55236',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
