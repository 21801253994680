export default {
  cake: {
    31229: '0x3b21662f581361c72d72f46b6982795241F55236',
    97: '',
  },
  masterChef: {
    31229: '0x3F7B90BeD38893C2998ecFc6823276c6488857ca',
    97: '',
  },
  wegc: {
    31229: '0x87e95a88EF421dc51b385FAFA87f64F21f5E3041',
    97: '',
  },
  lottery: {
    31229: '',
    97: '',
  },
  lotteryNFT: {
    31229: '',
    97: '',
  },
  mulltiCall: {
    31229: '0x95Bc58490f4793f699E55ab650E745D21Ee0eE06',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  ean: {
    31229: '0xf711d680dd3e987b6bd24253931e5495c954b557',
    97: '',
  },
  busd: {
    31229: '0xeb0fe9acA9B70b99043b9B50aa37bFF2B375Ca5f',
    97: '',
  },
}
