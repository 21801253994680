import { MenuEntry } from '@encodix/smexuikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Swap',
    icon: 'FarmIcon',
    href: 'https://dex.egonswap.app/#/swap',
  },
  {
    label: 'Liquidity',
    icon: 'FarmIcon',
    href: 'https://dex.egonswap.app/#/swap',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Analytics',
  //   icon: 'InfoIcon',
  //   href: 'https://info.egonswap.app',
  // },
  // {
  //   label: 'Audit',
  //   icon: 'InfoIcon',
  //   href: 'https://dessertswap.finance/audits/SMPROPERTIES-BSC-AUDIT-20964225.pdf',
  // },
]

export default config
